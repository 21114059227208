@import '../variables.less';


#messagesWrapper {

  padding-top: 20px;

  .message {

    margin-bottom: 20px;

    .right,{
      .bubble{
        float: right;

        &:before {
          content: "";
          position: absolute;
          top: 10px;
          right: -15px;
          left: auto;
          border-style: solid;
          border-width: 10px 0 10px 15px;
          border-color: transparent #EEEEEE;
          display: block;
          width: 0;
          z-index: 1;
        }

        &:after{
          display: none;
        }

      }
      .date{
        float: right;
        margin: 14px 0;
        padding: 10px;
      }
    }

    .bubble {

      position: relative;
      display: inline-block;
      background: #EEEEEE;
      padding: 10px;
      margin: 14px 15px;

      &.bg-primary{

        background: @primary-color;

        &:before{
          border-color: transparent @primary-color;
        }

        &:after{
          border-color: transparent @primary-color;
        }
      }

      &:after {
        content: "";
        position: absolute;
        top: 10px;
        left: -15px;
        border-style: solid;
        border-width: 10px 15px 10px 0;
        border-color: transparent #EEEEEE;
        display: block;
        width: 0;
        z-index: 1;
      }
    }
  }
}

.post-reply{
  margin-left: 50px;
  padding-left: 10px;
  border-left: 3px solid #DEDEDE;
}

.comment-links{
  display: inline;

  a{
    color: #000000;
    margin: 0 10px;
  }
}